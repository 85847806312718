import React, { useState, useEffect, useRef, useContext } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { getSearchPath } from "../utils/path"
import { Container, Text, Box, Grid, Flex } from "@theme-ui/components"
import { i18nContext } from "../context/i18nContext"
import { useFavicon } from "../hooks/useFavicon"
import VideoThumb from "../components/videoThumb"
import algoliasearch from "algoliasearch/lite"
import "instantsearch.css/themes/satellite.css"
import { connectRefinementList } from "react-instantsearch-dom"
import {
  Configure,
  Hits,
  InstantSearch,
  Pagination,
  RefinementList,
  SearchBox,
} from "react-instantsearch"

const searchClient = algoliasearch(
  "HYFSTKYEWR",
  "c54ab2b5b8ee289436cbaa27cb735e89"
)

const SearchPage = ({
  location,
  history,
  data: { site, videos, channel },
  pageContext,
}) => {
  const favicon = useFavicon().site.faviconMetaTags
  const algoliaIndex = {
    it: "it_video",
    en: "en_video",
    fr: "fr_video",
    de: "de_video",
  }

  const searchIndex = algoliaIndex[pageContext.locale]

  const i18nPaths = site.locales.map(locale => {
    return {
      locale: locale,
      value: getSearchPath(pageContext.channel, locale),
    }
  })

  return (
    <Layout
      pageSlug={pageContext.channel}
      locale={pageContext.locale}
      i18nPaths={i18nPaths}
    >
      <i18nContext.Consumer>
        {t => (
          <>
            <HelmetDatoCms seo={site.seoMetaTags} favicon={favicon}>
              <html lang={site.locale} />
              <title>{t.search}</title>
            </HelmetDatoCms>
            <Box sx={{ py: [9, 9], position: "relative" }}>
              <Container>
                <Text as="h1" variant="h1" color="dark">
                  {t.search}
                </Text>
              </Container>
              <Container sx={{ ".ais-Hits-list": { listStyleType: "none" } }}>
                <InstantSearch
                  searchClient={searchClient}
                  indexName={searchIndex}
                  routing={true}
                  initialUiState={{
                    [algoliaIndex[t.locale]]: {
                      refinementList: {
                        "channel.title": [`${channel.title}`],
                      },
                    },
                  }}
                >
                  <Configure hitsPerPage={12} />
                  <Box sx={{ mb: [4, 5] }}>
                    <SearchBox autoFocus />
                  </Box>
                  <Grid columns={[1, "1fr 5fr"]} gap={["32px", "32px"]}>
                    <Flex sx={{ flexDirection: "column" }}>
                      <Box sx={{ mb: [3] }}>
                        <Text as="p" sx={{ fontWeight: "700", mb: [1] }}>
                          {t.channel}
                        </Text>
                        <RefinementList
                          attribute="channel.title"
                          showMore
                          translations={{
                            showMoreButtonText: ({ isShowingMore }) =>
                              isShowingMore ? t.showLess : t.showMore,
                          }}
                        />
                      </Box>
                      <Box sx={{ mb: [3] }}>
                        <Text as="p" sx={{ fontWeight: "700", mb: [1] }}>
                          {t.language}
                        </Text>
                        <RefinementList
                          attribute="language"
                          showMore
                          translations={{
                            showMoreButtonText: ({ isShowingMore }) =>
                              isShowingMore ? t.showLess : t.showMore,
                          }}
                        />
                      </Box>
                      <Box sx={{ mb: [3] }}>
                        <Text as="p" sx={{ fontWeight: "700", mb: [1] }}>
                          {t.country}
                        </Text>
                        <RefinementList
                          attribute="country"
                          showMore
                          s
                          translations={{
                            showMoreButtonText: ({ isShowingMore }) =>
                              isShowingMore ? t.showLess : t.showMore,
                          }}
                        />
                      </Box>

                      <Box sx={{ mb: [3] }}>
                        <Text as="p" sx={{ fontWeight: "700", mb: [1] }}>
                          {t.category}
                        </Text>
                        <RefinementList
                          attribute="category.title"
                          showMore
                          translations={{
                            showMoreButtonText: () => t.showMore,
                          }}
                        />
                      </Box>
                    </Flex>
                    <Box
                      sx={{
                        ".ais-Hits-list": {
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr 1fr",
                        },
                        ".ais-Hits-item": {
                          alignItems: "baseline",
                        },
                      }}
                    >
                      <Hits
                        hitComponent={({ hit }) => (
                          <VideoThumb
                            search={true}
                            video={{ ...hit, locale: t.locale }}
                            category={hit.category}
                            theme={hit.channel.slug}
                            key={hit.objectID}
                          />
                        )}
                      />
                      <Box
                        sx={{
                          mt: [3, 4],
                          ".ais-Pagination-list": {
                            justifyContent: "center",
                          },
                        }}
                      >
                        <Pagination />
                      </Box>
                    </Box>
                  </Grid>
                </InstantSearch>
              </Container>
            </Box>
          </>
        )}
      </i18nContext.Consumer>
    </Layout>
  )
}

export default SearchPage

export const query = graphql`
  query SearchQuery($channel: String!, $locale: String!) {
    site: datoCmsSite {
      locales
    }
    channel: datoCmsPage(slug: { eq: $channel }, locale: { eq: $locale }) {
      title
    }
  }
`
